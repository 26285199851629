var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.badgeDetailsType)?_c('div',[_c('link',{attrs:{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css"}}),_c('script',{attrs:{"type":"application/javascript","src":"https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js"}}),(_vm.badgeDetailsType == 'PANORAMA')?_c('v-dialog',{attrs:{"scrollable":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","block":"","color":_vm.color}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fadl fa fa-image")]),_vm._v(" "+_vm._s(_vm.$t('events.badges.view-panorama'))+" ")],1)]}}],null,false,4162852801),model:{value:(_vm.panoramaVisible),callback:function ($$v) {_vm.panoramaVisible=$$v},expression:"panoramaVisible"}},[(_vm.badge)?_c('v-card',{attrs:{"color":"black"}},[_c('v-toolbar',{staticClass:"px-2",attrs:{"dark":"","color":"grey darken-4"}},[(!_vm.hideCloseButton)?_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.panoramaVisible = false}}},[_c('v-icon',[_vm._v("fa-times-circle")])],1):_vm._e(),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.badge.name))]),_c('v-spacer'),_c('ShareButton',{attrs:{"icon":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}})],1),(_vm.badge.description)?_c('div',{staticStyle:{"position":"absolute","z-index":"10","left":"0px","bottom":"00px","right":"0","background":"rgba(0,0,0, .45)","padding":"20px","color":"white"}},[_vm._v(" "+_vm._s(_vm.badge.description)+" ")]):_vm._e(),_c('div',{ref:"panorama",staticStyle:{"width":"100vw","height":"100vh"},attrs:{"id":"panorama"}})],1):_vm._e()],1):_vm._e(),(_vm.badgeDetailsType == 'STREET_VIEW')?_c('v-dialog',{attrs:{"scrollable":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","block":"","color":_vm.color}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fadl fa fa-street-view")]),_vm._v(" "+_vm._s(_vm.$t('events.badges.street-view'))+" ")],1)]}}],null,false,3405972713),model:{value:(_vm.streetViewVisible),callback:function ($$v) {_vm.streetViewVisible=$$v},expression:"streetViewVisible"}},[(_vm.badge)?_c('v-card',{attrs:{"color":"black"}},[_c('v-toolbar',{staticClass:"px-2",attrs:{"dark":"","color":"grey darken-4"}},[(!_vm.hideCloseButton)?_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.streetViewVisible = false}}},[_c('v-icon',[_vm._v("fa-times-circle")])],1):_vm._e(),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.badge.name))]),_c('v-spacer'),_c('ShareButton',{attrs:{"icon":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}})],1),(_vm.badge.description)?_c('div',{staticStyle:{"position":"absolute","z-index":"10","left":"0px","bottom":"00px","right":"0","background":"rgba(0,0,0, .45)","padding":"20px","color":"white"}},[_vm._v(" "+_vm._s(_vm.badge.description)+" ")]):_vm._e(),_c('div',{ref:"streetviewPlaceholder",staticClass:"full-dialog"})],1):_vm._e()],1):_vm._e(),(_vm.badgeDetailsType == 'IMAGE')?_c('v-dialog',{attrs:{"scrollable":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","block":"","color":_vm.color}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fadl fa fa-image")]),_vm._v(" "+_vm._s(_vm.$t('shared.view-image'))+" ")],1)]}}],null,false,2034784295),model:{value:(_vm.photoVisible),callback:function ($$v) {_vm.photoVisible=$$v},expression:"photoVisible"}},[(_vm.badge)?_c('v-card',{attrs:{"color":"black"}},[_c('v-toolbar',{staticClass:"px-2",attrs:{"dark":"","color":"grey darken-4"}},[(!_vm.hideCloseButton)?_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.photoVisible = false}}},[_c('v-icon',[_vm._v("fa-times-circle")])],1):_vm._e(),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.badge.name))]),_c('v-spacer'),_c('v-btn-toggle',{staticClass:"mr-2",model:{value:(_vm.photoFit),callback:function ($$v) {_vm.photoFit=$$v},expression:"photoFit"}},[_c('v-btn',{attrs:{"value":true}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("fa-arrows")]),_vm._v(" Fit ")],1)],1),_c('ShareButton',{attrs:{"icon":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}})],1),(_vm.badge.description)?_c('div',{class:_vm.photoFit ? '' : 'd-none',staticStyle:{"position":"absolute","z-index":"10","left":"0px","bottom":"00px","right":"0","background":"rgba(0,0,0, .45)","padding":"20px","color":"white"}},[_c('vue-markdown',{staticClass:"markdown mx-4",attrs:{"html":false,"source":_vm.badge.description}})],1):_vm._e(),_c('div',{staticClass:"full-dialog",staticStyle:{"overflow":"auto"}},[_c('img',{class:_vm.photoFit ? 'fit-image' : '',attrs:{"src":_vm.badge.details_img},on:{"click":function($event){_vm.photoFit=!_vm.photoFit}}})])],1):_vm._e()],1):_vm._e(),(_vm.badgeDetailsType == 'VIDEO')?_c('v-dialog',{attrs:{"scrollable":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","block":"","color":_vm.color}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fadl fa fa-video")]),_vm._v(" "+_vm._s(_vm.$t('shared.view-video'))+" ")],1)]}}],null,false,4139381799),model:{value:(_vm.videoVisible),callback:function ($$v) {_vm.videoVisible=$$v},expression:"videoVisible"}},[(_vm.badge)?_c('v-card',{attrs:{"color":"black"}},[_c('v-toolbar',{staticClass:"px-2",attrs:{"dark":"","color":"grey darken-4"}},[(!_vm.hideCloseButton)?_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.videoVisible = false}}},[_c('v-icon',[_vm._v("fa-times-circle")])],1):_vm._e(),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.badge.name))]),_c('v-spacer'),_c('ShareButton',{attrs:{"icon":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}})],1),(_vm.badge.description)?_c('div',{class:_vm.photoFit ? '' : 'd-none',staticStyle:{"background":"rgba(0,0,0, .45)","padding":"20px","color":"white"}},[_vm._v(" "+_vm._s(_vm.badge.description)+" ")]):_vm._e(),(_vm.badge.meta && _vm.badge.meta.some(function (x) { return x.key == 'apivideo.id'; }))?_c('div',[_c('ApiVideoPlayer',{attrs:{"videoId":_vm.badge.meta.find(function (x) { return x.key == 'apivideo.id'; }).val_str}})],1):_c('div',{ref:"videoPlaceholder",staticClass:"full-dialog",staticStyle:{"overflow":"auto"}})],1):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }